import React from "react";
import SST from "../img/l5.5.png";
import "../ServiceHome/ServiceHome.css";
import { Link } from 'react-router-dom';

import WOW from "wow.js";
import {useEffect} from "react";

const ServiceHome = () => {

    useEffect(()=>{
        new WOW().init();
      })

  return (
    <div>
      <section className="container">
        <h3 className="service-header">Nos Services</h3>
        <p className="sDetails">
          N'oubliez pas que malheuresement, un accident ou incendie peut arriver à tout moment et n'importe où. Selon les statistiques de la Fédération française de cardiologie,
          on dénombre <strong>180 000 décès par an d'origine cardio-vasculaire</strong>.<br/> ALORS une seule chose reste à faire: <strong>SE FORMER au secourisme !</strong>
          <br/> <br/>Nous vous proposons des formations dans différents thèmes :
        </p>
        <div className="row">        
          <div className="col animate__animated wow animate__fadeInTopLeft      text-center header" data-wow-delay="0.5s">
            <Link className = "link_style" to="service">
              <div className="service-card">
                <div className="service-holder">
                <img className="img4" src={SST} alt="web" />
                </div>
                <h4>SST</h4>
              </div>
              </Link>
          </div>         
          <div className="col animate__animated wow animate__fadeInTopLeft      text-center header" data-wow-delay="0.7s">
          <Link className = "link_style" to="service">
            <div className="service-card">
              <div className="service-holder">
              <img className="img4" src={SST} alt="web" />
              </div>
              <h4>MAC SST</h4>
            </div>
            </Link>
          </div>        
        </div>
      </section>
    </div>
  );
};

export default ServiceHome;
