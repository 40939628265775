import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import  "./Contact.css";
import "../../node_modules/animate.css";
import WOW from "wow.js";

const Contact = () => {
    const [data,setData] = useState({
        name : " ",
        phone: " ",
        email : " ",
        msg: " "

    })

    useEffect(()=>{
      new WOW().init();
    })
  

    const eventHandel = (e) =>{
        const {name,value} = e.target;
            setData((prev)=>{
                return{
                    ...prev,
                    [name] : value
                }    
            });
          
    }
  return (
    <div>
    <div className="container">
      <div className="row">
          <div className="col-12 col-md-3 col-lg-3 tag  animate__animated wow animate__bounceInUp" wow-data-delay="1s">
          <h6>Région</h6>
          <p>Ile-de-France</p>
          </div>
          <div className="col-12 col-md-1 col-lg-1"></div>
          <br/>
          <div className="col-12 col-md-3 col-lg-3 tag  animate__animated wow animate__bounceInUp" wow-data-delay="1s">
          <h6>Formateur au secourisme</h6>
          <p>Mr BRAHMI</p>
          </div>
          <div className="col-12 col-md-1 col-lg-1"></div>
          <br/>
          <div className="col-12 col-md-3 col-lg-3 tag  animate__animated wow animate__bounceInUp" wow-data-delay="1s">
          <h6>Courriel</h6>
          <p>afcformations@hotmail.fr</p>
          </div>         

      </div>
      </div>
    <main className="form  animate__animated wow animate__fadeInRight"> 
      <br/> <br/> <br/>
      <div className="my-5">

      <div className="text-center">
      Pour toute demande spécifique concernant l'accès ou équipement pour personne en situation d'handicap, merci de bien vouloir contacter
      ce numéro : <strong>+33 6 15 15 45 25</strong> ou bien par courriel à <strong>afcformations@hotmail.fr</strong>.
      <br/> <br/>  <br/> 
      </div>

        <h1 className="text-center"> Nous contacter </h1>
      </div>
      <div className="row">
        <div className="col-md-6 col-10 mx-auto">
          <form>
            <div className="mb-3">
              <label  className="form-label">
                Nom Prénom
              </label>
              <input
                type="text"
                name="name"
                value={data.name}
                onChange= {eventHandel}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Full Name"
              />
            </div>
            <div className="mb-3">
              <label  className="form-label">
                Téléphone
              </label>
              <input
                type="number"
                name = "phone"
                value = {data.phone}
                onChange= {eventHandel}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Numéro de Téléphone"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Courriel
              </label>
              <input
                type="email"
                name="email"
                value={data.value}
                onChange= {eventHandel}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder=" Adresse Email"
              />
            </div>

            <div class="mb-3">
              <label  className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                name = "msg"
                value = {data.value}
                onChange= {eventHandel}
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
          </form>
          <div className="col-12">
            <button className="btn btn-outline-primary" type="submit">
              Envoyer 
            </button>
          </div>
        </div>
      </div>
      <br/> <br/> 
    
      </main>
            
      <Footer></Footer>
    </div>
  );
};

export default Contact;
