import React from "react";
import "./ServiceItem.css";
import "../../node_modules/animate.css";
import { useState,useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import WOW from "wow.js";


const ServiceItem = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(()=>{
    new WOW().init();
  })

const SimpleList = ({ list }) => (
  <ul>
    {list.map(item => (
      <li key={item}>{item}</li>
    ))}
  </ul>
);


  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <div className="column">
      <div>
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <div className="card animate__animated wow animate__flipInY ">
          <div>
            <h2 className="text-center">{props.tittle}</h2>
            </div>
            <p><strong>Durée :  </strong> {props.duree}</p>
            <p><strong>Accès :  </strong> {props.public}</p>
            <p><strong>Modalité de formation :  </strong> {props.modalite_formation}</p>
            <p><strong>Modalité d'évaluation :  </strong> {props.modalite}</p>
            <p><strong>Méthodes de formation :  </strong> {props.methodes}</p>
            
            <p><strong>Prérequis :  </strong> {props.prerequis}</p>
            <p><strong>{props.participants_max}</strong></p>
            <button className="btn btn-success" onClick={handleClick}>En savoir plus</button>
          </div>  


          <div className="card">
            <h3 className="text-center">{props.tittle}</h3>

            <p><strong>Objectifs :  </strong>{props.des} </p>
            <h4 className="text-center">Contenu de la formation</h4>
            <p>{props.contenu}</p>

           <SimpleList list={props.contenu_liste} />

            <button onClick={handleClick}>Retour</button>
          </div>

        </ReactCardFlip>
      </div>
      <br/> <br/>
    </div>
  );
};

export default ServiceItem;
