

const serviceItems = [{
    "key" : "1",
    
    "tittle" : " Formation SST",
    "description": "Disposer, dans tous les établissements ainsi que sur les chantiers, de personnel en nombre adapté capables d’intervenir immédiatement après tout accident. Promouvoir la prévention des risques professionnels.",
    "duree": "14 heures",
    "prerequis": "Aucun",
    "modalite" : "Mise en situation finale pour l'obtention de la certification SST",
    "modalite_formation" : "Face à Face",
    "methodes": "Méthodes pédagogiques centrées sur la pratique et la mise en situation",
    "public": "Tout public. Un public en situation de handicap peut être formé à cette formation. Le formateur fera le lien avec le client si du matériel spécifique est nécessaire (chaise adaptée, etc) et fera le lien avec les autres stagiaires du groupe qui peuvent être des personnes ressources.",
    "participants_max": "4 à 10 participants maximum",
    "imgSrc" : "../img/l5.1.png",
    "contenu" : "9 principes généraux de prévention pour guider à chaque instant les actions de celles et de ceux qui s’impliquent dans la lutte contre les risques professionnels.",
    "contenu_liste" : ["Eviter les risques", 
                        "Evaluer les risques qui ne peuvent pas être évités",
                        "Combattre les risques à la source",
                        "Adapter le travail à l’homme",
                        "Tenir compte de l’évolution de la technique",
                        "Remplacer ce qui est dangereux par ce qui ne l’est pas ou par ce qui l’est moins",
                        "Planifier la prévention",
                        "Donner la priorité aux mesures de protection collective",
                        "Former et informer les salariés sur les risques et leur prévention"]



},
// {   
//     "key" : "3",
    
//     "tittle" : "Formation EPI",
//     "description": "Connaître les notions élémentaires et avancées de naissance et de propagation d’un incendie. Savoir choisir et utiliser judicieusement le moyen de lutte le plus approprié, maîtriser les procédures d’évacuation ainsi que les techniques et conduites à tenir permettant d’éteindre efficacement un début d’incendie. Comprendre l’importance de la prévention dans la lutte contre l’incendie.",
//     "duree": "3 heures",
//     "prerequis": "Aucun",
//     "modalite" : "Mise en situation finale pour l'obtention de la certification SST",
//     "modalite_formation" : "Face à Face",
//     "methodes": "Méthodes pédagogiques centrées sur la pratique et la mise en situation",
//     "public": "Tout public",
//     "participants_max": "10 participants maximum",

//     "contenu" : "Extinctions sur feux réels au moyen d’un générateur de flammes écologique.",
//     "contenu_liste" : ["La théorie du feu",
//                         "Le triangle du feu",
//                         "Les différentes classes de feu",
//                         "Les agents extincteurs (CO2, EPI, poudre, RIA)"]


// },
// {   
//     "key" : "4",
    
//     "tittle" : "Formation au défibrilateur",
//     "description": "Être capable de porter secours à une personne en arrêt cardio-respiratoire tout en assurant sa propre sécurité ainsi que celle des tiers,d'identifier l'organisation des secours au sein de sa structure et d'alerter correctement et d'effectuer les gestes salvateurs face à un arrêt cardio-respiratoire à l'aide d'un défibrillateur automatisé externe en attendant l’arrivée des secours organisés.",
//     "duree":"2 heures",
//     "prerequis": "Aucun",
//     "modalite" : "Mise en situation sur cas pratique",
//     "modalite_formation" : "Face à Face",
//     "public": "Tout public",
//     "participants_max": "10 participants maximum",
//     "contenu" : "L'évaluation de la formation est réalisée à travers différentes mises en situations pratiques permettant d'apprécier l'acquisition des différents gestes de secours et conduites à tenir.",
//     "contenu_liste" : ["La protection et la prévention",
//                         "Examiner la victime",
//                         "Faire alerter ou alerter",
//                         "Secourir",
//                          "Le Défibrillateur",
//                          "Situation où la victime ne répond pas, mais elle respire",
//                          "Situation où la victime ne répond pas et ne respire pas"]

// },
{   
    "key" : "2",    
    "tittle" : "MAC SST",
    "description": "Le SST devra avoir les connaissances gestuelles face à une victime accidentée et promouvoir la prévention dans sa société. Le formateur prendra soin de contrôler l’acquisition des connaissances et des compétences tout au long de la formation afin de recadrer, reformuler, et gérer du mieux que possible les participants et le temps de formation (évaluations formatives).",   "duree": "7 heures",
    "duree":"7 heures (recyclage tous les 2 ans)",
    "prerequis": "Aucun",
    "modalite" : "Mise en situation finale pour l'obtention de la certification SST",
    "modalite_formation" : "Face à Face",
    "methodes": "Méthodes pédagogiques centrées sur la pratique et la mise en situation",
    "public": "Tout public. Un public en situation de handicap peut être formé à cette formation. Le formateur fera le lien avec le client si du matériel spécifique est nécessaire (chaise adaptée, etc) et fera le lien avec les autres stagiaires du groupe qui peuvent être des personnes ressources.",
    "participants_max": "4 à 10 participants maximum",



    "contenu" : "9 principes généraux de prévention pour guider à chaque instant les actions de celles et de ceux qui s’impliquent dans la lutte contre les risques professionnels.",
    "contenu_liste" : ["Eviter les risques", 
                        "Evaluer les risques qui ne peuvent pas être évités",
                        "Combattre les risques à la source",
                        "Adapter le travail à l’homme",
                        "Tenir compte de l’évolution de la technique",
                        "Remplacer ce qui est dangereux par ce qui ne l’est pas ou par ce qui l’est moins",
                        "Planifier la prévention",
                        "Donner la priorité aux mesures de protection collective",
                        "Former et informer les salariés sur les risques et leur prévention"]

}
  

];

export default serviceItems;