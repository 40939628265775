import React from "react";
import Sdata from "../Sdata/Sdata";
import ServiceItem from "../ServiceItem/ServiceItem";
import Pdf from "../Files/plaquette.pdf";
import Pdf_sst from "../Files/Programme_initiale_SST.pdf";
import Pdf_mac from "../Files/Programme_MAC_SST.pdf";


const Service = () => {
  return (
    <div>
     <div className="my-5">
        <h1 className="text-center work"> Nos Services </h1>
      </div>
       <div className="text-center work">
                <a href = {Pdf} target = "_blank">Plaquette de formations</a>
      </div>

      <div className="text-center work">
                <a href = {Pdf_sst} target = "_blank">Programme de la formation initiale SST</a>
      </div>

      <div className="text-center work">
                <a href = {Pdf_mac} target = "_blank">Programme de la formation MAC SST</a>
      </div>

      <div className="container-fluid work">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row row-cols-md-4 gy-4 mt-5">
                    {Sdata.map((value)=>{
                        return <ServiceItem
                           key={value.key}
                           img={value.imgSrc}
                           tittle={value.tittle}
                           des={value.description}
                           duree = {value.duree}
                           modalite_formation = {value.modalite_formation}
                           modalite = {value.modalite}
                           methodes = {value.methodes}
                           prerequis = {value.prerequis}
                           public = {value.public}
                           participants_max = {value.participants_max}
                           contenu = {value.contenu}
                           contenu_liste = {value.contenu_liste}
                          />  
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default Service;
