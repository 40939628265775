import React from "react";
import {useEffect} from "react";
import Footer from "../Footer/Footer";
import "./About.css";
import "../../node_modules/animate.css";
import recom from "../img/recom.png";
import WOW from "wow.js";
import Reussite_SST from "../Files/Indicateur_de_reussite_SST_2020.pdf";
import Reussite_MAC_SST from "../Files/Indicateur_de_reussite_MAC_SST_2020.pdf";



const About = () => {

  useEffect(()=>{
    new WOW().init();
  })

  return (
    <div>
      <main className="container">
        <div>
   <p className="first">Résultats et</p>
          <p className="second">avis</p>
          <br />
          <br />

        {/* <Reussite/> */}


          <div className="row">            
             <div className = "row-18">
                <a href = {Reussite_SST} target = "_blank" rel="noreferrer">Indicateurs de Réussite 2020 Formation initiale SST</a>
                <br /><br />
                <a href = {Reussite_MAC_SST} target = "_blank" rel="noreferrer">Indicateurs de Réussite 2020 Formation MAC SST</a>
           </div>
          </div>
        </div>




        <div>
          
          <br />
        </div>
        <br /> <br/> 
        <br />
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
            <p className="first">Contexte du </p>
              <p className="second">COVID-19</p>
              <br />
              <br/>
              <img className = "context_img" src={recom} />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <br/><br/>
              
              <p className="product-details  animate__animated animate__backInRight">
                <h5>Les recommandations de l'INRS</h5>
                Afin d’accompagner les organismes et les entreprises habilités pour la reprise de l’activité formation SST, 
                l’INRS recommande <strong>les mesures barrières et les distanciations physiques </strong> tout au long des formations.
                <br/><br/>
                Pour éviter la transmission du virus lors des formations de secourisme, 
                l'INRS recommande aux SST, formateurs et élèves de respecter les gestes barrières. 
                Par conséquent, le choix du mannequin est une étape primordiale pour limiter les risques 
                de contamination et faciliter la désinfection.
                  <br/><br/>
                 Le matériel est désinfecté <strong>avant, pendant et après </strong> la formation et entre chaque passage d'un stagiaire.
                 Gel hydroalcoolique, lingettes désinfectantes et masques sont mis à disposition des stagiaires.
                  <br/><br/>
                  Aération de la salle à chaque pause et autant que possible.


              </p>
            </div>
          </div>
          <br/><br/> <br/>
          
      </main>
      <br/><br/>
      <Footer></Footer>
    </div>
  );
};

export default About;

// <div className="row">
//             <div className="col-12 col-md-6 col-lg-6">
             
//             <p className="product-details animate__animated wow animate__backInRight">
//             <h5>School Management System </h5>
//             <h1 style={{ color: "red" }}>Coming Soon..</h1>
//           </p>
              
//             </div>
//             <div className="col-12 col-md-6 col-lg-6">
//             <img  src={learning} alt="learning" />
//             </div>
//           </div>