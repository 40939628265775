import React from "react";
import "./Commom.css";
import { NavLink } from "react-router-dom";
import "../../node_modules/animate.css";
import Typical from 'react-typical';

const Common = (props) => {
  return (
    <section id="header" className="d-flex content-justify-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">         
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-2 d-flex content-justify-center flex-column">                
                <h1 className="animate__animated animate__backInRight last-name">{props.last_name}</h1>               
                <h3 className="work">{props.work}</h3>                
                <h6 className="first_name" >
                  Des formations de secourisme {''}
                  <Typical
                  loop={Infinity}
                  wrapper='b'
                  steps={[
                    'avec un matériel adapté !',
                    1000,
                    'à domicile !',                    
                    1000,
                    'dans le respect des gestes barrières ',
                    1000,
                    'aux normes ',
                    1000,
                    'à jour !',
                    1000,
                    'complètes !',
                    1000   
                  ]}
                  ></Typical>
                </h6>
                 <br/>
                <div className = "center">
                  <NavLink to={props.visit} className="btn-get-started">
                    {props.btnName}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-1">
                {<img className="home-img" src={props.img} alt="home-img"/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Common;
