import React from "react";
import * as css from "./Whyus.css";
import efficace from "../img/efficace.svg";
import sur_place from "../img/heavy_box2.svg";
import recyclage from "../img/certificate3.svg";
import WOW from "wow.js";
import "../../node_modules/animate.css";
import {useEffect} from "react";

const Reussite = () => {

    useEffect(()=>{
        new WOW().init();
      })

  return (
    <div>
      <div className="container">
        <section className="whyus">
          <p className="text-center chose-header animate__animated wow animate__fadeInUp" data-wow-delay="0.3s"> A.F.C Formations vous propose un service </p>
          <div className="row">
                <div className="col animate__animated wow animate__fadeInUp text-center" data-wow-delay="0.4s">
                  <div className="whyus-card card-body h-100 flex-fill">
                    <div className="icon">
                      <div>
                        <img className="img-cards" src={efficace} alt="anytime" />
                      </div>
                      <h3>Efficace</h3>
                      <div className="details">
                        <p className="text-center">
                          Cours et mise en pratique des activités à l'aide de <strong>mises en situation</strong> et d'un <strong>matériel adapté</strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col animate__animated wow animate__fadeInUp text-center" data-wow-delay="0.6s">
                  <div className="whyus-card card-body h-100 flex-fill">
                    <div className="icon">
                      <div>
                        <img className="img-cards" src={sur_place} alt="anytime" />
                      </div>
                      <h3>Sur place</h3>
                      <div className="details">
                        <p className="text-center">
                          Le formateur se déplace pour dispenser sa séance <strong>au sein de vos locaux</strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col animate__animated wow animate__fadeInUp text-center" data-wow-delay="0.8s">
                  <div className="whyus-card card-body h-100 flex-fill">
                    <div className="icon">
                      <div>
                        <img className="img-cards" src={recyclage} alt="anytime" />
                      </div>
                      <h3>Aux normes</h3>
                      <div className="details">
                        <p className="text-center">
                        Le moniteur de secourisme suit régulièrement un recyclage lui permettant <strong>de maintenir et d'actualiser ses compétences</strong> au près d'organismes de formation certifiés.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Reussite;
