import React from "react";
import countcss from "./ProjectCount.css";
import WOW from "wow.js";
import {useEffect} from "react";
import stats from "../img/undraw_site_stats_l57q.svg";

const ProjectCount = () => {

    useEffect(()=>{
        new WOW().init();
      })

  return (
    
      <div className="container con-count">
            <h3>Statistiques</h3>

            <div className="col-10 mx-auto">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-1">
                  {<img src={stats} alt="home-img"/>}
              </div>

              <div className="col col-row animate__animated wow animate__lightSpeedInLeft" wow-data-delay="1s">
                  <div className="row  con-row">
                  <p>Taux de réussite (2020)</p>
                  <strong><p>100 %</p></strong>
                  </div>
                  <div className="row  con-row">
                  <p>Taux d'abandon (2020)</p>
                  <strong><p>0 %</p></strong>
                  </div>
                  <div className="row con-row">
                  <p>Nombre de stagiaires (2020) </p>
                  <strong><p>170</p></strong>
                  </div>
              </div>
            </div>
            </div>

      </div>
    
  );
};

export default ProjectCount;
